import { decryptData } from '@/components/crypto_helpers'

const ROLE_PRUEFER = 'Pruefer'
const ROLE_STUDENT = 'Student'
const ROLE_PRAXISREFERAT = 'Praxisreferat'

function getUserObj() {
  try {
    const input = localStorage.getItem('user')
    return JSON.parse(decryptData(input))
  }
  catch (error) {
    return null
  }
}

function isStudent() {
  const obj = getUserObj()
  const role = obj?.role
  return role === ROLE_STUDENT
}

function isPruefer() {
  const obj = getUserObj()
  const role = obj?.role
  return role === ROLE_PRUEFER
}

function isPraxisreferat() {
  const obj = getUserObj()
  const role = obj?.role
  return role === ROLE_PRAXISREFERAT
}

function hasModuladministrationRole() {
  const obj = getUserObj()
  const extra_roles = obj?.extra_roles
  const role_configs = obj?.role_configs
  return extra_roles?.includes(role_configs?.ROLE_MODULADMINISTRATION)
}

function hasPORespRole() {
  const obj = getUserObj()
  const extra_roles = obj?.extra_roles
  const role_configs = obj?.role_configs
  return extra_roles?.includes(role_configs?.ROLE_POADMINPROZESS_PO_RESPONSIBLE)
}

function hasPraktikumadminRole() {
  const obj = getUserObj()
  const extra_roles = obj?.extra_roles
  const role_configs = obj?.role_configs
  return extra_roles?.includes(role_configs?.ROLE_PRAXISREFERAT)
}

function hasClearingadministrationRole() {
  const obj = getUserObj()
  const extra_roles = obj?.extra_roles
  const role_configs = obj?.role_configs
  return extra_roles?.includes(role_configs?.ROLE_CLEARINGADMINISTRATION)
}

export { hasModuladministrationRole, hasPraktikumadminRole, hasPORespRole, isPruefer, isStudent, isPraxisreferat, hasClearingadministrationRole, getUserObj }
