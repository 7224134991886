import { createI18n } from 'vue-i18n'
import messages from '@/lang'

export function setupI18n(options = { locale: 'de' }) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy')
    i18n.global.locale = locale
  else
    i18n.global.locale.value = locale

  document.querySelector('html').setAttribute('lang', locale)
}

const locale = localStorage.getItem('locale') ?? 'de'

const i18n = setupI18n({
  locale,
  fallbackLocale: 'de',
  messages,
})

export default i18n
