import createPersistedState from 'vuex-persistedstate'

const requireModule = require.context(
  '.',
  true,
  /\.js$/,
)
const modules = {}

requireModule.keys().forEach((fileName) => {
  if (fileName === './index.js')
    return

  const path = fileName.replace(/(\.\/|\.js)/g, '')
  const [moduleName, imported] = path.split('/')
  if (!modules[moduleName]) {
    modules[moduleName] = {
      namespaced: true,
    }
  }

  modules[moduleName][imported] = requireModule(fileName).default
})

export const plugins = [
  createPersistedState({
    paths: ['drawertoggle'],
  }),
]

export default modules
