/** @format */
import moment from 'moment'

export default {
  getFormattedDatetime(value, locale = 'de') {
    if (!value)
      return ''

    let formatDatetimeString = 'DD.MM.YYYY HH:mm'

    switch (locale) {
      case 'custom':
        formatDatetimeString = 'YYYY-MM-DD HH:mm'
        break
      case 'en':
        formatDatetimeString = 'YYYY-MM-DD hh:mm A'
        break
      case 'de':
      default:
        formatDatetimeString = 'DD.MM.YYYY HH:mm'
        break
    }

    return moment(new Date(String(value)), moment.ISO_8601).format(formatDatetimeString)
  },
  getFormattedDate(value, locale = 'de') {
    // Datumsformat deutsch
    if (!value)
      return ''

    if (locale) {
      if (locale === 'de')
        return moment(String(value)).format('DD.MM.YYYY')

      if (locale === 'en')
        return moment(String(value)).format('YYYY-MM-DD')
    }

    return value
  },
  getDateFromISODateString(value) {
    return moment(String(value), moment.ISO_8601)
  },
  getDateFromString(value) {
    // e.g. 19.07.21 08:00
    if (value && value.length === 14)
      return moment(value, 'DD.MM.YY HH:mm').toDate()

    if (value && value.length === 8)
      return moment(value, 'DD.MM.YY').toDate()

    if (value && value.length === 10)
      return moment(value, 'YYYY-MM-DD').toDate()

    return null
  },
  replaceNewLineTag(value) {
    return value.replace(('\\r?\\n', 'g'), '<br />')
  },
  isObject(item) {
    return (
      typeof item === 'object' && !Array.isArray(item) && item !== null
    )
  },
  getErrorMessage(error) {
    // general error message
    let output = 'Server error'
    let errors = ''
    // from error object, different data structure
    if (error) {
      if (error.response) {
        const data = error.response.data
        if (data) {
          if (data.non_field_errors) {
            errors = data.non_field_errors
          }
          else {
            if (!!data && data.constructor === Array) {
              errors = data.join('\n')
            }
            else if (this.isObject(data)) {
              if (
                Object.prototype.hasOwnProperty.call(
                  data,
                  'detail',
                )
              ) {
                errors = data.detail
              }
              else {
                errors = Object.keys(data).reduce(
                  (a, b, i) =>
                    `${a}${i ? '&' : ''}${b}: ${data[b]}`,
                  '',
                )
              }
            }
            else {
              errors = error
            }
          }
        }
      }
      else if (error.message) {
        errors = error.message
      }
      else {
        if (!!error && error.constructor === Array)
          errors = error.join('\n')
        else
          errors = error
      }
    }

    if (Array.isArray(errors))
      output = errors.join(',')
    else
      output = String(errors)

    return output
  },
  getDayForDatePicker(date, locale = 'de') {
    const daysOfWeekDE = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA']
    const daysOfWeekEN = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

    const i = new Date(date).getDay(date)
    if (locale === 'en')
      return daysOfWeekEN[i]
    else
      return daysOfWeekDE[i]
  },
  parseBoolean(str) {
    return /^true$/i.test(str)
  },
  getDateDiffNow(tillDateStr, labels = { 'Notification.today': 'today', 'Notification.tomorrow': 'tomorrow' }, locale = 'de', humanReadable = true) {
    const startOfDay = moment().startOf('day')
    const tillDate = moment(String(tillDateStr))
    const diffHours = tillDate.diff(startOfDay, 'hours')
    if (diffHours <= 0)
      return labels['Notification.today']
    else if (diffHours < 48)
      return labels['Notification.tomorrow']
    else
      return moment.duration(tillDate.diff(startOfDay)).locale(locale).humanize(humanReadable)
  },
  capitalize(input) {
    if (!input || input.length === 0)
      return ''
    else
      return input.charAt(0).toUpperCase() + input.slice(1)
  },
  debounce(func, wait = 400, immediate, ...args) {
    let timeout
    return function () {
      const context = this
      const later = function () {
        timeout = null
        if (!immediate)
          func.apply(context, args)
      }
      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow)
        func.apply(context, args)
    }
  },
  getFormattedTime(value, locale = 'de') {
    // Zeitformat deutsch
    if (!value)
      return ''

    if (locale === 'de')
      return moment(String(value)).format('HH:mm')

    if (locale === 'en')
      return moment(String(value)).format('hh:mm A')

    return value
  },
  startOfWeek(date) {
    const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)

    return new Date(date.setDate(diff))
  },
}
