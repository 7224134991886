import { createApp } from 'vue'
import App from './App.vue'
import i18n from '@/i18n/index'
import helpers from '@/components/helpers.js'
import router from '@/router'
import store from '@/store'
import { genericApi } from '@/plugins/axios'
import vuetify from '@/plugins/vuetify'

import 'maz-ui/styles'
import '@/styles/index.scss'
import '@/assets/css/peregos.css'

const app = createApp(App)
app.config.globalProperties.$helpers = helpers
app.config.globalProperties.$http = genericApi
app.use(vuetify).use(i18n).use(store).use(router).mount('#app')
