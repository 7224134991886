const config = {
  Sidebar_drawer: false,
  mini_sidebar: false,
  actTheme: 'LightTheme',
  fontTheme: 'Roboto',
}

const faviconEl = document.querySelector('link[rel="icon"]')

// init
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  faviconEl.setAttribute('href', `${process.env.BASE_URL}peregos-favicon-dark.png`)
}
else {
  faviconEl.setAttribute('href', `${process.env.BASE_URL}peregos-favicon.png`)
}

// listen change
const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
mediaQuery.addEventListener('change', themeChange)
function themeChange(event) {
  if (event.matches) {
    faviconEl.setAttribute('href', `${process.env.BASE_URL}peregos-favicon-dark.png`)
  }
  else {
    faviconEl.setAttribute('href', `${process.env.BASE_URL}peregos-favicon.png`)
  }
}

export default config
