import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue'
import store from '../store'
import paths from './paths'

const privateEnvVar = ''
const DEFAULT_TITLE = 'peregos'

const router = createRouter({
  base: '/',
  history: createWebHistory(privateEnvVar),
  routes: paths,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        const resizeObserver = new ResizeObserver((entries) => {
          if (!Array.isArray(entries) || !entries.length)
            return

          if (entries?.[0]?.target?.clientHeight >= screen.height) {
            resolve({ left: 0, top: savedPosition.y })
            resizeObserver.disconnect()
          }
        })
        resizeObserver.observe(document.body)
      }
      else if (to.hash) {
        const resizeObserver = new ResizeObserver((entries) => {
          if (!Array.isArray(entries) || !entries.length)
            return

          if (entries?.[0]?.target?.clientHeight >= screen.height) {
            resolve({ selector: to.hash })
            resizeObserver.disconnect()
          }
        })
        resizeObserver.observe(document.body)
      }
      else {
        return { x: 0, y: 0 }
      }
    })
  },
})

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.meta.requiresAuth)
    && !store.getters.authorized
    && !store.getters.tokenRefreshed
  ) {
    next('/login')
  }
  else if (
    to.name === 'login'
    && store.getters.authorized
    && !store.getters.tokenRefreshed
  ) {
    next('/')
  }
  else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  next()
})

router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
