const LightTheme = {
  name: 'LightTheme',
  dark: false,
  variables: {
    'border-color': '#f0f0f0',
    'carousel-control-size': 10,
    'gradient': 'linear-gradient(250.38deg, #e6f4ff 2.39%, #69b1ff 34.42%, #1677ff 60.95%, #0958d9 84.83%, #002c8c 104.37%)',
    'gradient2': 'linear-gradient(to right, rgb(9, 89, 218), rgb(22, 119, 255))',
    'card-shadow': '0px 1px 4px rgba(0, 0, 0, 0.08)',
  },
  colors: {
    primary: '#3a72ba',
    secondary: '#5ea1ff',
    tertiary: '#5ea1ff',
    info: '#ff9800',
    success: '#8bc34a',
    danger: '#dc3545',
    accent: '#26C6DA',
    warning: '#ff5722',
    error: '#f44336',
    general: '#2196F3',
    anchor: '#ffc107',
    background: '#FFFFFF',
    lightprimary: '#e6f4ff',
    lightsecondary: '#5ea1ff',
    lightsuccess: '#EAFCD4',
    lighterror: '#FFE7D3',
    lightwarning: '#FFF6D0',
    darkText: '#212121',
    lightText: '#8c8c8c',
    darkprimary: '#0958d9',
    darksecondary: '#5ea1ff',
    borderLight: '#e6ebf1',
    inputBorder: '#a1a1a5',
    containerBg: '#fafafb',
    surface: '#fff',
  },
}

export { LightTheme }
