import helpers from '@/components/helpers'

import i18n from '@/i18n'

const poFields = [
  {
    Name: 'Qualifikationsziele',
    Label: i18n.global.t('ModulAdmin.Qualifikationsziele'),
    Editable: false,
  },
]

const modulFields = [
  {
    Name: 'Name',
    Label: i18n.global.t('ModulAdmin.ModulnameTitel'),
    Editable: true,
  },
  {
    Name: 'ECTS',
    Label: i18n.global.t('ModulAdmin.ECTSTitel'),
  },
  {
    Name: 'SpracheName',
    Label: i18n.global.t('ModulAdmin.SpracheTitel'),
  },
  {
    Name: 'Dauer',
    Label: i18n.global.t('ModulAdmin.ModuldauerTitel'),
  },
  {
    Name: 'Kompetenzen',
    Label: i18n.global.t('ModulAdmin.KompetenzenTitel'),
    Editable: true,
  },
  {
    Name: 'Teilnahmevoraussetzungen',
    Editable: true,
    Label: i18n.global.t('ModulAdmin.TeilnahmevoraussetzungenTitel'),
  },
  {
    Name: 'Modulpruefung',
    Label: i18n.global.t('ModulAdmin.ModulpruefungTitel'),
  },
  {
    Name: 'Vorleistung',
    Label: i18n.global.t('ModulAdmin.VorleistungTitel'),
  },
  {
    Name: 'Hinweise',
    Editable: true,
    Label: i18n.global.t('ModulAdmin.HinweiseTitel'),
  },
]

const poModulFields = [
  {
    Name: 'Sharing',
    FieldForContent: 'SharingName',
    Label: i18n.global.t('ModulAdmin.SharingTitel'),
  },
  {
    Name: 'Fachsemester',
    Label: i18n.global.t('ModulAdmin.FachsemesterTitel'),
  },
  {
    Name: 'Modulstatus',
    Label: i18n.global.t('ModulAdmin.ModulstatusTitel'),
  },
  {
    Name: 'Modulnummer',
    Label: i18n.global.t('ModulAdmin.ModulnummerTitel'),
  },
  {
    Name: 'Alphanumerische Modulnummer',
    FieldForContent: 'ModulnummerAlpha',
    Label: i18n.global.t('ModulAdmin.ModulnummerAlphaTitel'),
  },
  {
    Name: 'Fachsemester',
    Label: i18n.global.t('ModulAdmin.FachsemesterTitel'),
  },
  {
    Name: 'Frequenz',
    Label: i18n.global.t('ModulAdmin.LehrveranstaltungsangebotTitel'),
  },
  {
    Name: 'Voraussetzungen',
    Editable: true,
    Label: i18n.global.t('ModulAdmin.VoraussetzungenTitel'),
  },
  {
    Name: 'Verwendbarkeit',
    Editable: true,
    Label: i18n.global.t('ModulAdmin.VerwendbarkeitTitel'),
  },
]

const unitFields = [
  {
    Name: 'Name',
    Editable: true,
    Label: i18n.global.t('ModulAdmin.UnitNameTitel'),
  },
  {
    Name: 'Fachcode',
    FieldForContent: 'FachcodeName',
    Label: i18n.global.t('ModulAdmin.UnitFachcodeTitel'),
    hidden: true,
  },
  {
    Name: 'SWS',
    Label: i18n.global.t('ModulAdmin.UnitSWSTitel'),
  },
  {
    Name: 'Lehrform',
    FieldForContent: 'LehrformName',
    Label: i18n.global.t('ModulAdmin.LehrformTitel'),
  },
  {
    Name: 'Inhalte',
    Editable: true,
    Label: i18n.global.t('ModulAdmin.InhalteTitel'),
  },
  {
    Name: 'Literatur',
    Editable: true,
    Label: i18n.global.t('ModulAdmin.LiteraturTitel'),
  },
  {
    Name: 'Praesenzzeit',
    Label: i18n.global.t('ModulAdmin.PraesenzzeitTitel'),
  },
  {
    Name: 'Pruefungszeit',
    Label: i18n.global.t('ModulAdmin.PruefungszeitTitel'),
  },
  {
    Name: 'Praxiszeit',
    Label: i18n.global.t('ModulAdmin.PraxiszeitTitel'),
  },
  {
    Name: 'Selbstlernzeit',
    Label: i18n.global.t('ModulAdmin.SelbstlernzeitTitel'),
  },
]

const modulPLFields = [
  {
    Name: 'PFName',
    Label: i18n.global.t('ModulAdmin.Pruefungsform'),
  },
  {
    Name: 'Dauer',
    Label: i18n.global.t('ModulAdmin.Duration'),
  },
  {
    Name: 'MinDauer',
    Label: i18n.global.t('ModulAdmin.MinDuration'),
  },
  {
    Name: 'MaxDauer',
    Label: i18n.global.t('ModulAdmin.MaxDuration'),
  },
  {
    Name: 'Bearbeitungszeit',
    Label: i18n.global.t('ModulAdmin.ProjektarbeitDauer'),
  },
  {
    Name: 'BearbeitungszeitEinheiten',
    Editable: true,
    Label: i18n.global.t('ModulAdmin.Einheiten'),
  },
  {
    Name: 'Gewichtung',
    Label: i18n.global.t('ModulAdmin.Gewichtung'),
  },
  {
    Name: 'IstTPL',
    Label: i18n.global.t('ModulAdmin.IstTPL'),
  },
  {
    Name: 'IVLabel',
    Label: i18n.global.t('ModulAdmin.Vorleistung'),
  },
  {
    Name: 'BSName',
    Label: i18n.global.t('ModulAdmin.Bewertungsschema'),
  },
]

function getFieldLabel(item) {
  const objType = item?.objType
  const fieldName = item?.fieldName

  let fieldLabel = ''

  switch (objType) {
    case 'PO':
    case 'Prüfungsordnung':
      fieldLabel = poFields.find(x => x.Name === fieldName)?.Label
      break
    case 'Unit':
      fieldLabel = unitFields.find(x => x.Name === fieldName)?.Label
      break
    case 'Prüfungsordnungsmodul':
    case 'POModul':
      fieldLabel = poModulFields.find(x => x.Name === fieldName)?.Label
      break
    case 'ModulPL':
      fieldLabel = modulPLFields.find(x => x.Name === fieldName)?.Label
      break
    case 'Modul':
    default:
      fieldLabel = modulFields.find(x => x.Name === fieldName)?.Label
      break
  }

  return fieldLabel
}

const dictObjTypesIndex = {
  Prüfungsordnung: 1,
  PO: 1,
  Prüfungsordnungsmodul: 5,
  POModul: 5,
  Modul: 10,
  Unit: 15,
}

const dictFieldNameIndex = {
  // PO
  'Qualifikationsziele': 1,

  // POModul
  'Sharing': 1,
  'Modulstatus': 2,
  'Modulnummer': 3,
  'Alphanumerische Modulnummer': 4,
  'Fachsemester': 5,
  'Frequenz': 6,
  'Voraussetzungen': 25,
  'Verwendbarkeit': 30,

  // Modul
  'Name': 1,
  'ECTS': 5,
  'SpracheName': 15,
  'Dauer': 17,
  'Kompetenzen': 20,
  'Teilnahmevoraussetzungen': 25,
  'Modulpruefung': 35,
  'Vorleistung': 40,
  'Hinweise': 50,

  // Unit
  // "Name": 1,
  'Fachcode': 5,
  'SWS': 10,
  'Lehrform': 15,
  'Inhalte': 20,
  'Literatur': 25,
  'Praesenzzeit': 30,
  'Pruefungszeit': 35,
  'Praxiszeit': 40,
  'Selbstlernzeit': 45,

  // ModulPL
  'PFName': 1,
  // "Dauer": 5,
  'MinDauer': 10,
  'MaxDauer': 15,
  'Bearbeitungszeit': 20,
  'BearbeitungszeitEinheiten': 25,
  'Gewichtung': 30,
  'IstTPL': 35,
  'IVLabel': 40,
  'BSName': 45,
}

function sortClearingeintragList(clearingeintragList, options = {}) {
  return clearingeintragList?.sort((a, b) => {
    if (options?.withPO) {
      // PO can be from different Version
      const aPOId = Number.parseInt(a.poId)
      const bPOId = Number.parseInt(b.poId)
      if (aPOId !== bPOId)
        return aPOId > bPOId ? 1 : -1

      return 1
    }

    if (['PO', 'Prüfungsordnung'].includes(b?.objType))
      return 1

    const aModulnummer = Number.parseInt(a.POModuls?.find(x => String(x.PO) === String(a.poId))?.Modulnummer)
    const bModulnummer = Number.parseInt(b.POModuls?.find(x => String(x.PO) === String(b.poId))?.Modulnummer)

    if (!aModulnummer)
      return -1

    if (aModulnummer !== bModulnummer)
      return aModulnummer > bModulnummer ? -1 : 1

    const aParsedObjTypIndex = dictObjTypesIndex[a.objType]
    const bParsedObjTypIndex = dictObjTypesIndex[b.objType]
    if (aParsedObjTypIndex !== bParsedObjTypIndex)
      return aParsedObjTypIndex > bParsedObjTypIndex ? 1 : -1

    if (!a.fieldName)
      return 1

    const aParsedFieldNameIndex = dictFieldNameIndex[a.fieldName]
    const bParsedFieldNameIndex = dictFieldNameIndex[b.fieldName]
    if (aParsedFieldNameIndex !== bParsedFieldNameIndex)
      return aParsedFieldNameIndex > bParsedFieldNameIndex ? -1 : 1

    return 1
  })
}

function sortModulList(poId, modulList) {
  modulList?.sort((a, b) => {
    const aModulnummer = Number.parseInt(a?.POModul?.find(x => String(x.PO) === String(poId))?.Modulnummer)
    const bModulnummer = Number.parseInt(b?.POModul?.find(x => String(x.PO) === String(poId))?.Modulnummer)
    if (aModulnummer !== bModulnummer)
      return aModulnummer - bModulnummer > 0 ? 1 : -1

    return 1
  })
  return modulList
}

function getFilteredClearingeintragList(clearingeintragList, filterOptions) {
  const typeList = [
    'Prüfungsordnung',
    'PO',
    'Prüfungsordnungsmodul',
    'POModul',
    'Modul',
    'Unit',
    'Modulprüfungsleistung',
    'ModulPL',
  ]

  let filteredList = clearingeintragList

  if (filterOptions?.fieldName && filterOptions?.objId) {
    filteredList = clearingeintragList?.filter(
      x => (
        (!x.fieldName && typeList.includes(x.objType))
        || (
          x.fieldName && x.fieldName === filterOptions?.fieldName && typeList.includes(x.objType)
        )
      )
      && (!x.Text || x.Text instanceof String)
      && (
        (!filterOptions?.referenzId && String(x.objId) === String(filterOptions?.objId))
        || (
          filterOptions?.referenzId && String(x.ModulReferenzId) === String(filterOptions?.referenzId)
        )
      ),
    )
  }

  if (filterOptions?.Status?.length > 0)
    filteredList = filteredList?.filter(x => filterOptions?.Status?.includes(x.Clearingstatus))

  return sortClearingeintragList(filteredList)
}

function getClearingFieldList(poId, modulList) {
  return modulList?.map((modul, indexModul) => {
    const poModul = modul.POModul?.find(x => String(x.PO) === String(poId)) ?? modul.POModul?.[0]

    const modulHeader = {
      id: `Modul-${modul.id}_Header-${indexModul}`,
      poId,
      modulId: modul.id,
      objId: modul.id,
      objType: 'Modul',
      meta: 'Header',
      fieldName: '',
      content: modul.Name,
      editable: false,
      isPOEditable: true,
      isNewPossible: true,
      modulNummer: poModul?.Modulnummer,
      modulNummerAlpha: poModul?.ModulnummerAlpha,
      Modulname: modul?.Modulname,
      fieldVisible: true,
      isShared: modul.is_shared,
      ModulReferenzId: modul.Referenz,
      modulReadonly: modul.readonly,
    }

    const modulClearingFieldList = modulFields.map((field, indexField) => {
      return {
        id: `Modul-${modul.id}_Field-${indexField}_FieldName-${field.Name}`,
        poId,
        modulId: modul.id,
        objId: modul.id,
        objType: 'Modul',
        fieldName: field.Name,
        content: modul[field.Name] ?? '',
        editable: field.Editable ?? false,
        isPOEditable: true,
        isNewPossible: true,
        modulNummer: poModul?.Modulnummer,
        modulNummerAlpha: poModul?.ModulnummerAlpha,
        Modulname: modul?.Modulname,
        fieldVisible: true,
        ModulReferenzId: modul.Referenz,
        modulReadonly: modul.readonly,
      }
    })

    const poModulHeader = {
      id: `Modul-${modul.id}_POModulHeader`,
      poId,
      modulId: modul.id,
      objId: poModul?.id,
      objType: 'POModul',
      meta: 'Header',
      fieldName: '',
      content: poModul?.Name,
      editable: false,
      isPOEditable: true,
      isNewPossible: false,
      modulNummer: poModul?.Modulnummer,
      modulNummerAlpha: poModul?.ModulnummerAlpha,
      Modulname: modul?.Modulname,
      fieldVisible: true,
    }

    const poModulClearingFieldList = poModulFields.map((field, indexField) => {
      return {
        id: `Modul-${modul.id}_POModulField-${indexField}_FieldName-${field.Name}`,
        poId,
        modulId: modul.id,
        objId: poModul?.id,
        objType: 'POModul',
        fieldName: field.Name,
        content: field.FieldForContent ? poModul[field.FieldForContent] : poModul[field.Name],
        editable: field.Editable ?? false,
        isPOEditable: true,
        isNewPossible: true,
        modulNummer: poModul?.Modulnummer,
        modulNummerAlpha: poModul?.ModulnummerAlpha,
        Modulname: modul?.Modulname,
        fieldVisible: true,
        ModulReferenzId: modul.Referenz,
        modulReadonly: modul.readonly,
      }
    })

    let unitsClearingFieldList = []
    modul.units?.forEach((unit, indexUnit) => {
      const unitHeader = {
        id: `Modul-${modul.id}_UnitHeader-${indexUnit}`,
        poId,
        modulId: modul.id,
        objId: unit.id,
        objType: 'Unit',
        meta: 'Header',
        fieldName: '',
        content: unit.Name,
        editable: false,
        isPOEditable: true,
        isNewPossible: true,
        modulNummer: poModul?.Modulnummer,
        modulNummerAlpha: poModul?.ModulnummerAlpha,
        fieldVisible: true,
        ModulReferenzId: modul.Referenz,
        Modulname: modul?.Modulname,
        modulReadonly: modul.readonly,
      }

      const unitClearingFieldList = unitFields.map((field, indexField) => {
        return {
          id: `Modul-${modul.id}_Unit-${indexUnit}_Field-${indexField}_-FieldName-${field.Name}`,
          poId,
          modulId: modul.id,
          objId: unit.id,
          objType: 'Unit',
          fieldName: field.Name,
          content: field.FieldForContent ? unit[field.FieldForContent] : unit[field.Name] ?? '',
          editable: field.Editable ?? false,
          isPOEditable: true,
          isNewPossible: true,
          modulNummer: poModul?.Modulnummer,
          modulNummerAlpha: poModul?.ModulnummerAlpha,
          fieldVisible: true,
          ModulReferenzId: modul.Referenz,
          Modulname: modul?.Modulname,
          modulReadonly: modul.readonly,
        }
      })
      unitsClearingFieldList = [...unitsClearingFieldList, unitHeader, ...unitClearingFieldList]
    })

    return [modulHeader, ...modulClearingFieldList, poModulHeader, ...poModulClearingFieldList, ...unitsClearingFieldList]
  }).flat()
}

const objTypList = {
  Prüfungsordnung: 'PO',
  PO: 'PO',
  Modul: 'Modul',
  Unit: 'Unit',
  Prüfungsordnungsmodul: 'POModul',
  POModul: 'POModul',
  Modulprüfungsleistung: 'ModulPL',
  ModulPL: 'ModulPL',
}

function getNumOfClearingeintraege(list, objType, objId, fieldName, referenzId = null, closedItem = false) {
  if (!list || !Array.isArray(list))
    return 0

  if (fieldName) {
    return list.filter(
      x => x.fieldName
      && x.fieldName === fieldName
      && ['PO', 'Modul', 'POModul', 'Unit', 'ModulPL'].includes(objTypList[x.objType])
      && objTypList[x.objType] === objTypList[objType]
      && x.Text !== Object(x.Text)
      && (
        objType === 'Modul'
        || (
          objType !== 'Modul' && String(x.objId) === String(objId)
        )
      )
      && (
        (!referenzId && String(x.objId) === String(objId))
        || (
          referenzId && String(x.ModulReferenzId) === String(referenzId)
        )
      )
      && ((!closedItem && x.Clearingstatus !== 'ERLEDIGT') || (closedItem && x.Clearingstatus === 'ERLEDIGT')),
    )?.length ?? 0
  }

  return list.filter(
    x => !x.fieldName
    && ['PO', 'Modul', 'POModul', 'Unit', 'ModulPL'].includes(objTypList[x.objType])
    && x.Text === Object(x.Text)
    && String(x.objId) === String(objId),
  ).length ?? 0
}

function getFieldIndex(items, options) {
  if (items?.length < 1)
    return

  const index = options?.fieldName
    ? items.findIndex(field =>
      field.objType === objTypList[options?.objType]
      && (
        (options?.objType === 'Modul' && field.ModulReferenzId === options?.modulReferenzId)
        || (
          options?.objType !== 'Modul' && field.objId === options?.objId
        )
      )
      && field.fieldName && field.fieldName === options?.fieldName,
    )
    : items.findIndex(field =>
      field.objType === objTypList[options?.objType]
      && (
        (options?.objType === 'Modul' && field.ModulReferenzId === options?.modulReferenzId)
        || (
          options?.objType !== 'Modul' && field.objId === options?.objId
        )
      )
      && !field.fieldName,
    )
  return index < 0 ? 0 : index
}

function getActiveModuls(modulList, personId) {
  const endOfToday = new Date()
  endOfToday.setHours(23, 59, 59, 999)

  return modulList?.filter((m) => {
    const ppCurrent = m.person_permissions?.find(
      x => String(x.id) === String(personId),
    )
    const endOfDayForFrist = helpers.getDateFromString(
      ppCurrent?.Frist,
    )
    if (endOfDayForFrist) {
      endOfDayForFrist.setHours(23, 59, 59, 999)
      return (
        helpers.parseBoolean(ppCurrent?.current_version)
        && endOfDayForFrist >= endOfToday
      )
    }

    return false
  })
}

function getNavList(poId, modulList) {
  return modulList?.map((x, index) => {
    const poModul = x.POModul?.find(x => String(x.PO) === String(poId))
    const Modulnummer = poModul ? poModul.Modulnummer : null
    const ModulnummerAlpha = poModul ? poModul.ModulnummerAlpha : null
    const modulNavItem = {
      id: `Nav-${index + 1}`,
      type: 'ModulNav',
      poId,
      name: x.Name,
      desc: x.Name,
      modulId: x.id,
      ModulName: x.Name,
      Modulnummer,
      ModulnummerAlpha,
      objId: x.id,
      objType: 'Modul',
      ModulReferenzId: x.Referenz,
    }

    const unitNavItemList = x.units?.map((unit, indexUnit) => {
      return {
        id: `Nav-${index + 1}, Unit${indexUnit}`,
        type: 'UnitNav',
        poId,
        name: unit.Name,
        desc: unit.Name,
        modulId: x.id,
        Modulnummer,
        ModulnummerAlpha,
        ModulName: x.Name,
        UnitId: unit.id,
        UnitName: unit.Name,
        objId: unit.id,
        objType: 'Unit',
      }
    })
    return [modulNavItem, ...unitNavItemList]
  }).flat()
}

function getStatusList(withClosed = false) {
  if (!withClosed) {
    return [
      { id: 'INIT', Label: i18n.global.t('ModulAdmin.Erstellt') },
      { id: 'IGNOR', Label: i18n.global.t('ModulAdmin.Ignoriert') },
      { id: 'BEARB', Label: i18n.global.t('ModulAdmin.Bearbeitet') },
    ]
  }
  else {
    return [
      { id: 'INIT', Label: i18n.global.t('ModulAdmin.Erstellt') },
      { id: 'IGNOR', Label: i18n.global.t('ModulAdmin.Ignoriert') },
      { id: 'BEARB', Label: i18n.global.t('ModulAdmin.Bearbeitet') },
      { id: 'ERLEDIGT', Label: i18n.global.t('ModulAdmin.Abgeschlossen') },
    ]
  }
}

export {
  modulFields,
  unitFields,
  poModulFields,
  objTypList,
  getStatusList,
  getFieldLabel,
  sortClearingeintragList,
  getFilteredClearingeintragList,
  getClearingFieldList,
  getNumOfClearingeintraege,
  getFieldIndex,
  sortModulList,
  getActiveModuls,
  getNavList,
}
